/* BEGIN TRANSLATION */

const hint = {
    format: "%yy / %yyyy - tahun, %mm - bulan, %dd - hari, %nn - penomboran",
    "can-be-multiple": "Boleh pilihan banyak",
    "token-infinite": "Token tidak luput",
    "token-expires": "Token tamat pada {0}"
};

/* ENDED TRANSLATION */
export { hint };
/* BEGIN TRANSLATION */

const message = {
    "login-failed": "Gagal log masuk, sila cuba sebentar lagi",
    "login-required": "Sila log masuk untuk meneruskan",
    "discard-confirmation": "Adakah anda pasti mahu membuang perubahan semasa?",
    "cancel-order1": "Pastikan anda mahu batalkan pesanan yang dipilih?",
    "cancel-order2": "Pastikan anda mahu batalkan pesanan yang dipilih ({0})?",
    "reject-order": "Pastikan anda mahu menolak pesanan yang dipilih?",
    "finalize-order": "Pastikan anda mahu muktamadkan pesanan yang dipilih?",
    "delivered-order": "Pastikan anda mahu menukar status jadi dihantar?",
    "reverse-order": "Pastikan anda mahu balikkan pesanan yang dipilih?",
    "finalize-payment": "Pastikan anda mahu muktamadkan pembayaran yang dipilih?",
    "approve-payment": "Pastikan anda mahu luluskan pembayaran yang dipilih?",
    "cancel-payment": "Pastikan anda mahu batalkan pembayaran yang dipilih?",
    "reject-payment": "Pastikan anda mahu menolak pembayaran yang dipilih?",
    "delivered-payment": "Pastikan anda mahu menukar status jadi dihantar?",
    "refunded-payment": "Pastikan anda mahu menukar status jadi dikembali?",
    "approve-transfer": "Pastikan anda mahu luluskan pemindahan yang dipilih?",
    "cancel-transfer": "Pastikan anda mahu batalkan pemindahan yang dipilih?",
    "record-saved": "Rekod berjaya disimpan",
    "record-deleted": "Rekod berjaya dipadamkan",
    "invalid-mode": "Mod tidak sah",
    "record-not-found": "Rekod tidak dijumpai",
    "no-address-added": "Tidak ada alamat yang ditambahkan",
    "address-city-required": "Alamat bandar diperlukan",
    "delete-record1": "Pastikan anda mahu memadam rekod yang dipilih?",
    "delete-record2": "Pastikan anda mahu menghapus rekod yang dipilih ({0})?",
    "delete-record3": "Pastikan anda mahu memadam rekod ini?",
    "not-logged-in": "Anda tidak log masuk atau tamat waktu sesi, sila log masuk untuk meneruskan",
    authenticating: "Mengesahkan, sila tunggu ...",
    "load-comment-failed": "Gagal memuatkan komen, mencuba semula ...",
    "link-facebook-confirmation": "Pastikan anda mahu kaitkan Facebook akaun anda? Anda tidak membuat asal kemudian lagi.",
    "link-account-confirmation": "Pastikan anda mahu kaitkan ke '{0}'? Anda tidak boleh membuat asal kemudian lagi.",
    "facebook-not-linked": "Anda belum kaitkan Facebook akaun anda, sila kaitkan akaun anda dan cuba lagi.",
    "account-not-selected": "Sila pilih akaun untuk meneruskan.",
    "live-session-nothing-selected": "Sila pilih sesi siaran untuk meneruskan.",
    "link-account-successful": "Facebook akaun berjaya dikaitkan.",
    "refresh-token-successful": "Sesi Facebook telah disegarkan kerana masa tamat.",
    "no-account-found": "Tidak ada Facebook akaun yang dijumpai.",
    "no-video-found": "Tidak ada video yang ditemui.",
    "video-not-selected": "Sila pilih video untuk meneruskan.",
    "nothing-selected": "Sila pilih rekod untuk meneruskan.",
    "selected-video-confirmation": "Pastikan anda mahu guna video '{0}', pada '{1}'?",
    "live-session-stop-confirmation": "Pastikan anda mahu akhirkan sesi siaran sekarang?",
    "live-session-delete-confirmation": "Pastikan anda mahu memadamkan sesi siaran ini?",
    "live-session-product-not-introducing": "Tidak ada sesi siaran produk diperkenalkan, buka dengan tetapan lalai?",
    "order-accepted": "Pesanan diterima ({0}, {1}, {2})",
    "key-in-order-quantity": "Sila masukkan kuantiti pesanan",
    "invalid-number": "Sila masukkan nombor yang sah dan lebih daripada sifar",
    "cart-quantity": "Kuantiti pesanan / kuantiti checkout",
    "shipping-not-selected": "Sila pilih alamat penghantaran.",
    "country-nothing-selected": "Tidak ada negara yang dipilih",
    "checkout-nothing-selected": "Sila pilih pesanan untuk checkout.",
    "transfer-nothing-selected": "Sila pilih pesanan untuk memindah.",
    "state-nothing-selected": "Tidak ada negeri yang dipilih",
    "city-nothing-selected": "Tidak ada bandar yang dipilih",
    "please-select-receipt": "Sila pilih resit untuk dimuat naik.",
    "payment-pending-approval": "Terima kasih atas pembayaran anda, sila tunggu kelulusan pembayaran anda {0}.",
    "transfer-pending-approval": "Permintaan pemindahan diterima, sila tunggu kelulusan pemindahan anda {0}.",
    "key-in-cancel-quantity": "Sila masukkan kuantiti untuk batalkan",
    "shipping-fee-pricing-description": "Setiap berat {0} g akan berharga {1}.",
    "clipboard-copied": "Disalin ke papan keratan.",
    "customer-group-event-conflict": "Acara ini bertentangan dengan yang rekod lain.",
    "session-closing-all-notified": "Semua pelanggan telah diberitahu.",
	"live-session-comment-date1": "Lebih kurang {0} saat yang lalu",
	"live-session-comment-date2": "Lebih kurang {0] minit yang lalu",
	"video-type-not-defined": "Jenis video tidak ditentukan",
	"invalid-facebook-account-id": "Facebook akaun yang tidak sah",
	"order-reverted": "Pesanan dibalikan",
	"revert-last-order-confirmation": "Pastikan anda mahu kembalikan order ({0}, {1}, {2})?",
	"default-product-opened-successfully": "Produk lalai berjaya dibuka",
    "profile-close-account": {
        "w1": "Ingatkan akaun yang ditutup akan mengelapkan data pengguna anda dan tindakan ini tidak boleh batalkan selepasnya.",
        "w2": "Sila masukkan '",
        "w3": "' kalau nak teruskan.",
        "alert": "Masukkan 'Tutup Akaun' kalau nak teruskan mengelap data pengguna anda."
    }
};

/* ENDED TRANSLATION */
export { message };
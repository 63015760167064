/* LANG: MS */
/* MASTER FILE */

import { $vuetify } from "./basic-ms/common";
import { title } from "./basic-ms/title";
import { text } from "./basic-ms/text";
import { message } from "./basic-ms/message";
import { hint } from "./basic-ms/hint";

export default {
    $vuetify,
    title,
    text,
    message,
    hint
}
/* BEGIN TRANSLATION */

const $vuetify = {
    noDataText: "Tidak ada data",
    dataTable: {
        itemsPerPageText: "Bilangan Rekod",
        sortBy: "Susun mengikut",
        ariaLabel: {
            sortNone: "Tiada menyusun",
            sortAscending: "Susunan menaik",
            sortDescending: "Susunan menurun",
            activateNone: "Tiada",
            activateAscending: "Susunan menaik",
            activateDescending: "Susunan menurun"
        }
    },
    dataFooter: {
        prevPage: "Sebelumnya",
        nextPage: "Seterusnya",
        pageText: "{0} - {1} dari {2}",
        itemsPerPageAll: "Semua"
    },
    dataIterator: {
        noResultsText: "Tiada rekod yang sepadan",
        loadingText: "Memuatkan..."
    },
    fileInput: {
        counter: "{0} fail",
        counterSize: "{0} fail ({1} keseluruhan)"
    },
    pagination: {
        ariaLabel: {
            currentPage: "Halaman Sekarang, Halaman {0}",
            next: "Seterusnya",
            previous: "Sebelumnya",
            wrapper: "Navigasi Penomboran",
            page: "Pergi Halaman {0}"
        }
    },
    carousel: {
        ariaLabel: {
            delimiter: "Slaid karusel {0} dari {1}"
        },
        prev: "Visual sebelumnya",
        next: "Visual seterusnya"
    },
    datePicker: {
        prevMonthAriaLabel: "Bulan lepas",
        nextMonthAriaLabel: "Bulan depan"
    },
    timePicker: {
        am: "AM",
        pm: "PM"
    }
};

/* ENDED TRANSLATION */
export { $vuetify };
/* BEGIN TRANSLATION */

const text = {
    ok: "Ok",
    yes: "Ya",
    no: "Tidak",
    login: "Log Masuk",
    cancel: "Batal",
    profile: "Profil",
    logout: "Log Keluar",
    "login-with-facebook": "Log Masuk dengan Facebook",
    settings: "Tetapan",
    username: "Nama pengguna",
    password: "Kata Laluan",
    company: "Syarikat",
    code: "Kod",
    name: "Nama",
    management: "Pengurusan",
    customer: "Pelanggan",
    "registration-no": "No Pendaftaran",
    "tax-registration-no": "No Pendaftaran Cukai",
    "discard-changes": "Buang Perubahan",
    "save-changes": "Simpan Perubahan",
    "delete-record": "Padam Rekod",
    address: "Alamat",
    "printing-address": "Mencetak Alamat",
    actions: "Tindakan",
    close: "Tutup",
    submit: "Hantar",
    line1: "Baris 1",
    line2: "Baris 2",
    line3: "Baris 3",
    line4: "Baris 4",
    postcode: "Poskod",
    city: "Bandar",
    state: "Negeri",
    country: "Negara",
    email: "E-mel",
    "contact-no": "No Hubungan",
    "contact-person": "Orang Hubungan",
    create: "Baru",
    modify: "Ubah",
    "delete-selected": "Padam Terpilih",
    "running-number": "Nombor Larian",
    "record-count": "Jumlah Rekod",
    refresh: "Menyegarkan",
    delete: "Padam",
    format: "Format",
    length: "Panjang",
    sample: "Contoh",
    "reset-by": "Tetapkan Semula Oleh",
    none: "Tiada",
    daily: "Setiap Hari",
    monthly: "Bulanan",
    yearly: "Tahunan",
    "change-password": "Tukar Kata Laluan",
    "current-password": "Kata Laluan Sekarang",
    "system-setting": "Penetapan Sistem",
    "full-name": "Nama Penuh",
    "facebook-linked": "Kaitkan Facebook",
    user: "Pengguna",
    "new-password": "Kata Laluan Baru",
    "confirm-password": "Sahkan Kata Laluan",
    "create-record": "Rekod Baru",
    "finalize-record": "Muktamadkan Rekod",
    birthday: "Tarikh Lahir",
    gender: "Jantina",
    male: "Lelaki",
    female: "Perempuan",
    "customer-group": "Kumpulan Pelanggan",
    "purchase-amount-limit": "Had Amaun Pembelian",
    "purchase-quantity-limit": "Had Kuantiti Pembelian",
    back: "Kembali",
    "address-book": "Buku Alamat",
    "shipping-address": "Alamat Penghantaran",
    sales: "Jualan",
    "live-session": "Sesi Siaran",
    "session-date": "Tarikh Sesi",
    "new-session": "Sesi Baru",
    "start-session": "Mulakan Sesi",
    "end-session": "Tamatkan Sesi",
    status: "Status",
    "live-session-info": "Maklumat Sesi Siaran",
    comment: "Komen",
    "comment-count": "Bilangan Komen",
    hide: "Sembunyikan",
    "link-facebook": "Kaitkan dengan Facebook",
    "product-introducing": "Produk Diperkenalkan",
    "product-code": "Kod Produk",
    "product-name": "Nama Produk",
    "weight-g": "Berat(g)",
    "total-weight-g": "Jumlah Berat(g)",
    quantity: "Kuantiti",
    "available-quantity": "Kuantiti Tersedia",
    price: "Harga",
    open: "Buka",
    ended: "Tamat",
    video: "Video",
    "confirmation-dialog": "Dialog Pengesahan",
    "history-count": "Bilangan Sejarah",
    account: "Akaun",
    "facebook-account": "Facebook Akaun",
    "facebook-url": "Facebook Alamat",
    link: "Kaitkan",
    "started-date": "Tarikh Bermula",
    "ended-date": "Tarikh Tamat",
    bidding: "Menawar",
    remarks: "Kenyataan",
    deposit: "Deposit",
    save: "Simpan",
    send: "Hantar",
    "accept-order": "Terima Pesanan",
    ordered: "Pesanan",
    symbol: "Simbol",
    common: "Biasa",
    currency: "Mata Wang",
    currencies: "Mata Wang",
    "total-amount": "Jumlah Amaun",
    "live-sessions": "Sesi Siaran",
    "product-sales": "Jualan Produk",
    customers: "Pelanggan",
    "closing-no": "Penutup No",
    "closing-date": "Tarikh Penutup",
    "session-closing": "Sesi Penutup",
    "finalized-date": "Tarikh Muktamadkan",
    "add-live-session": "Tambah Sesi Siaran",
    import: "Import",
    "import-all": "Import Semua",
    "line-no": "Bil.",
    "total-quantity": "Jumlah Kuantiti",
    notify: "Maklumkan",
    "notify-all": "Maklumkan Semua",
    message: "Mesej",
    notified: "Dimaklumkan",
    notification: "Pemberitahuan",
    "expires-at": "Tamat Pada",
    "password-enabled": "Kata Laluan Diaktifkan",
    summary: "Ringkasan",
    subtotal: "Jumlah Kecil",
    "shipping-fee": "Kos Penghantaran",
    checkout: "Checkout",
    "payment-method": "Kaedah Pembayaran",
    receipt: "Resit",
    wallet: "Dompet",
    mixed: "Campur",
    products: "Produk",
    payment: "Pembayaran",
    "payment-no": "Pembayaran No",
    "payment-date": "Tarikh pembayaran",
    "login-enabled": "Log Masuk Diaktifkan",
    administrator: "Pentadbir",
    "user-info": "Maklumat Pengguna",
    "customer-order": "Pesanan Pelanggan",
    "customer-code": "Kod Pelanggan",
    "customer-name": "Nama Pelanggan",
    "pending-amount": "Jumlah Belum Selesai",
    "customer-payment": "Bayaran Pelanggan",
    fullscreen: "Skrin Penuh",
    description: "Penerangan",
    regions: "Wilayah",
    "all-countries": "Semua Negara",
    "all-states": "Semua Negeri",
    "all-cities": "Semua Bandar",
    "add-region": "Tambah Wilayah",
    addresses: "Alamat",
    "capture-order": "Menangkap Pesanan",
    "capture-format": "Format Menangkap",
    "comment-name-color": "Warna Nama Komen",
    "comment-message-color": "Warna Mesej Komen",
    "comment-background-color": "Warna Belakang Komen",
    "comment-tag-color": "Warna Tag Komen",
    voucher: "Baucar",
    "voucher-length": "Panjang Baucar",
    "discount-target": "Sasaran Diskaun",
    "discount-overall": "Semua (Jumlah Kecil & Kos Penghantaran)",
    "discount-amount": "Amaun Diskaun",
    "discount-percent": "Peratus Diskaun",
    "maximum-discount": "Diskaun Maksimum",
    "minimum-order-amount": "Amaun Pesanan Minimum",
    "maximum-order-amount": "Amaun Pesanan Maksimum",
    "minimum-total-amount": "Jumlah Amaun Minimum",
    "maximum-total-amount": "Jumlah Amaun Maksimum",
    "payment-amount": "Jumlah Pembayaran",
    "repeat-usage": "Ulangi Penggunaan",
    discontinued: "Dihentikan",
    pricing: "Harga",
    "weight-step-g": "Langkah Berat(g)",
    "standard-delivery": "Penghantaran Biasa",
    "on-hold-delivery": "Tahan Penghantaran",
    "store-pickup": "Pengambilan Stor",
    orders: "Pesanan",
    "receipt-payment": "Pemayaran Resit",
    "wallet-payment": "Pembayaran Dompet",
    "total-payment": "Jumlah Pembayaran",
    details: "Perincian",
    order: "Pesanan",
    "order-no": "Pesanan No",
    "order-date": "Tarikh Pesanan",
    "customer-order-detail": "Pesanan Pelanggan Perincian",
    draft: "Draf",
    "to-pay": "Bayar",
    processing: "Memproses",
    paid: "Dibayar",
    delivered: "Dihantar",
    cancelled: "Dibatal",
    expired: "Tamat",
    rejected: "Ditolak",
    completed: "Selesai",
    "cancel-order": "Batalkan Pesanan",
    "finalize-order": "Muktamadkan Pesanan",
    "reject-order": "Tolak Pesanan",
    "move-record": "Pindah Rekod",
    existing: "Sedia Ada",
    "new-order": "Pesanan Baru",
    "sub-order": "Sub Pesanan",
    "new-order-status": "Status Pesanan Baru",
    "approve-payment": "Luluskan Pembayaran",
    "cancel-payment": "Batalkan Pembayaran",
    "reject-payment": "Tolak Bayaran",
    "finalize-payment": "Muktamadkan Pembayaran",
    "cash-payment": "Pembayaran Tunai",
    "wallet-balance": "Baki Dompet",
    refunded: "Dikembali",
    "select-all": "Pilih Semua",
    type: "Jenis",
    "generate-token": "Hasilkan Token",
    token: "Token",
    miscellaneous: "Pelbagai",
    "customer-token-expires-minutes": "Token Pelanggan Tamat (Minit)",
    never: "Tidak Akan",
    copy: "Salinan",
    "request-no": "Permintaan No",
    "request-date": "Tarikh Permintaan",
    "request-by-code": "Permintaan Oleh Kod",
    "request-by-name": "Permintaan Oleh Nama",
    "transfer-to-code": "Pindah Ke Kod",
    "transfer-to-name": "Pindah Ke Nama",
    "transfer-order": "Pindah Pesanan",
    transfer: "Pindah",
    "selected-orders": "Pesanan Terpilih",
    "transfer-token": "Token Transfer",
    "transfer-request": "Permintaan Pindahan",
    "request-by": "Permintaan Oleh",
    "transfer-to": "Pindah Ke",
    "approve-transfer": "Luluskan Pemindahan",
    "cancel-transfer": "Batalkan Pindahan",
    view: "Melihat",
    "date-format": "Format tarikh",
    "time-format": "Format masa",
    search: "Cari",
    clear: "Jelas",
    filter: "Tapis",
    all: "Semua",
    preparing: "Menyiapkan",
    started: "Bermula",
    closed: "Ditutup",
    transferred: "Dipindah",
    finalized: "Muktamadkan",
    select: "Pilih",
    generate: "Hasilkan",
    "print-receipt": "Cetakan Resit",
    "date-from": "Tarikh Dari",
    "date-to": "Tarikh Hingga",
    "empty-date": "Tarikh Kosong",
    "voucher-numbers": "Nombor Baucar",
    "voucher-number": "Nombor Baucar",
    "delivery-method": "Cara Penghantaran",
    approved: "Dilulus",
    transferring: "Memindah",
    "transfer-date": "Tarikh Pindah",
    "customer-group-event": "Kumpulan Pelanggan Acara",
    events: "Acara",
    "order-extend-expires": "Tempoh Memanjang Pesanan Tamat (hari)",
    "deposit-extend-expires": "Tempoh Memanjang Deposit Tamat (hari)",
    "checkout-extend-expires": "Tempoh Memanjang Pembayaran Tamat (hari)",
    "cancelled-extend-expires": "Tempoh Memanjang Dibatal Tamat (hari)",
    "order-extend-count": "Jumlah Memanjang Pesanan",
    "deposit-extend-count": "Jumlah Memnjang Deposit",
    "checkout-extend-count": "Jumlah Memanjang Checkout",
    "cancelled-extend-count": "Jumlah Memanjang Dibatal",
    disabled: "Tidak Aktif",
    rank: "Perinkat",
    "current-balance": "Baki Terkini",
    "adjustment-amount": "Jumlah Penyesuaian",
    "adjusted-balance": "Baki Disesuaikan",
    adjust: "Menyesuaikan",
    reason: "Sebab",
    cash: "Wang Tunai",
    expand: "Kembangkan",
    "add-order": "Tambah Pesanan",
    upload: "Muat Naik",
    "comment-message": "Mesej Komen",
    "session-no": "Sesi No",
    postage: "Pos",
    shipment: "Penghantaran",
    "product-price": "Harga Produk",
    "product-weight-g": "Berat Produk(g)",
    "expiration-date": "Tarikh Luput",
    reverse: "Berbalik",
    "allowed-delivery-methods": "Cara Penghantaran Yang Dibenarkan ",
    "standard-product-prices": "Harga Produk Standard",
    unnamed: "Tanpa Nama",
    "contact-us": "Hubungi Kami",
    "registration-name": "Pendaftaran Nama",
    "our-store": "Kedai Kami",
    "our-facebook-page": "Alamat Facebook Kami",
    "email-address": "Alamat E-Mel",
	"comment-info": "Maklumat Komen",
	"search-comment": "Cari Komen",
    "login-history": "Sejarah Log Masuk",
    "login-date": "Tarikh Log Masuk",
    "login-source": "Sumber Log Masuk",
	exclude: "Kecualikan",
    standard: "Standard",
    facebook: "Facebook",
    streamer: "Streamer",
    "auto-reload": "Auto Memuat Semula",
    "comment-date": "Tarikh Komen",
    reply: "Balas",
    pending: "Belum Selesai",
    "record-date": "Tarikh",
	psid: "PSID",
	"request-by-psid": "Permintaan Oleh PSID",
	"transfer-to-psid": "Pindah Ke PSID",
	"video-selection": "Pemilihan Video",
	"live-videos": "Video Siaran",
	"prev-videos": "Video Sebelumnya",
	"exclude-linked": "Kecuali Sudah Kaitkan",
	"preset-product": "Produk pratetap",
	"conversation-url": "Perbualan Url",
	navigating: "Menavigasi",
	navigate: "Navigasi",
	"name-history": "Nama Sejarah",
	export: "Eksport",
	"created-date": "Tarikh Dibuat",
	"exclude-accepted": "Kecuali Sudah Diterima",
	"more": "Lebih",
	"enabled": "Aktif",
	"revert-last-order": "Kembalikan Pesanan Terakhir",
	"notify-template": "Templat Maklum",
	"notify-customer": "Maklum Pelanggan",
	"imtbot-access-token": "Token Akses Imtbot",
    "close-account": "Tutup Akaun",
    "confirmation": "Pengesahan",
    "infinite": "Tidak Terhingga"
};

/* ENDED TRANSLATION */
export { text };